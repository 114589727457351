import {Component, OnDestroy, OnInit} from '@angular/core'
import {UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms'
import {Subject, Subscription} from 'rxjs'
import {HemnetService, IHemnetData} from '../../services/hemnet.service'
import {DataService} from '../../services/data.service'
import {debounceTime, switchMap} from 'rxjs/operators'

/** Check some basic inputs before returning */
export const inputValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const expectedPrice = control.get('expectedPrice')
  const downPayment = control.get('downPayment')
  const interest = control.get('interestRate')
  const price = Number.parseInt(DataService.RemoveNonDigitsFromString(expectedPrice.value), 10)
  const down = Number.parseInt(DataService.RemoveNonDigitsFromString(downPayment.value), 10)

  if (down >= price) {
    return {tooLarge: true}
  }

  const rate = Number.parseFloat(interest.value)
  if (isNaN(rate)) {
    return {notNumber: true}
  }
  if (rate > 20) {
    return {tooHigh: true}
  }
  return null
}

@Component({
  selector: 'spb-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit, OnDestroy {

  public inputForm: UntypedFormGroup = new UntypedFormGroup({
    expectedPrice: new UntypedFormControl('', [Validators.required, Validators.maxLength(10)]),
    downPayment: new UntypedFormControl('', [Validators.required, Validators.maxLength(10)]), // 12 456 890 = 99 M SEK max
    interestRate: new UntypedFormControl('', [Validators.required, Validators.maxLength(5)]), // 10.51
    fee: new UntypedFormControl('', [Validators.maxLength(6)]),
    runCost: new UntypedFormControl('', Validators.maxLength(6)) // Six digits = 99 999
  }, inputValidator)

  public calculatedDownPayment: string

  public downPaymentError = false

  private hemnet$: Subscription

  private hemnetData$: Subject<IHemnetData>

  private hemnetData: IHemnetData

  constructor(
    private hemnetService: HemnetService
  ) {
  }

  ngOnInit(): void {
    this.hemnetData$ = this.hemnetService.hemnetData

    this.hemnet$ = this.hemnetService.hemnetOutput.subscribe({
      next: (hemnetData: IHemnetData) => {
        if (!hemnetData.expectedPrice) {
          hemnetData.expectedPrice = hemnetData.askingPrice
        }

        if (!hemnetData.downPayment) {
          hemnetData.downPayment = Math.ceil(hemnetData.expectedPrice * 0.15)
        }

        /**
         * Prettify the values.
         */
        const keysToFormat = ['expectedPrice', 'fee', 'downPayment', 'runCost']
        const copy = Object.assign({}, hemnetData)
        Object.keys(copy).forEach(key => {
          if (keysToFormat.indexOf(key) !== -1) {
            copy[key] = DataService.AddNumberGrouping(copy[key])
          }
        })

        // Set the initial form.
        this.inputForm.patchValue(copy)
        this.hemnetData = hemnetData
        this.setValues(this.hemnetData)
        // Send the data to listeners.
        this.hemnetData$.next(this.hemnetData)
        this.hemnetService.saveHemnetData(this.hemnetData)
      }
    })

    this.inputForm.valueChanges.subscribe((data) => {
      if (!this.inputForm.valid) {
        return
      }
      Object.assign(this.hemnetData, data)
      this.setValues(this.hemnetData)
      this.hemnetData$.next(this.hemnetData)
      this.hemnetService.saveHemnetData(this.hemnetData)
    })

    /**
     * This is serious over engineering! We could just as well save before we
     * navigate out, but I wanted to test this debouncce/switchmap thing.
     *
     * When the form is valid it emits, if we emit more than every three seconds
     * we simply skip those values and save the latest... hmm.
     */
    this.inputForm.valueChanges.pipe(
      debounceTime(3000),
      switchMap(() => this.hemnetService.saveHemnetData(this.hemnetData))
    ).subscribe((hemnetData: IHemnetData) => {
      HemnetService.CreateCookie(hemnetData)
    })
  }

  ngOnDestroy(): void {
    this.hemnet$.unsubscribe()
  }

  /**
   * Removes non digits from input field.
   *
   * @param event - The event to handle
   */
  removeNonDigits(event: any): void {
    event.target.value = DataService.AddNumberGrouping(event.target.value)
  }

  private setValues(hemnetData: IHemnetData) {
    const expectedPrice = DataService.StringToInt(hemnetData.expectedPrice)
    this.downPaymentError = Math.ceil(expectedPrice * 0.15) > DataService.StringToInt(hemnetData.downPayment)
    this.calculatedDownPayment = DataService.AddNumberGrouping(expectedPrice * 0.15)
  }
}
