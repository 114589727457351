<spb-footer [license]="true" [version]="version" copyrightYears="2020-2023"></spb-footer>
<div class="contact-holder">
  <div class="contact">
    <div class="logo">
      <div class="spb-logo"><a href="https://sparbankensyd.se">Sparbanken Syd</a></div>
    </div>
    <div class="contact-button">
      <a href="https://www.sparbankensyd.se/kontakt">Kontakta oss</a>
    </div>
  </div>
</div>
