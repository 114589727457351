<div class="main">
  <div class="top-holder">
    <div class="top">
      <div (click)="navigate('https://internetbank.sparbankensyd.se/')" class="internetbanken">
        <div>
          <em class="material-icons">
            lock_outline
          </em>
        </div>
        <div>
          Internetbanken
        </div>
      </div>
    </div>
  </div>
  <div class="middle-holder">
    <div class="middle">
      <div (click)="navigate('https://www.sparbankensyd.se/')" class="spb-logo">
        Sparbanken Syd
      </div>
      <div class="filler"></div>
    </div>
  </div>
</div>
