import {Component, OnInit} from '@angular/core'
import {Subscription} from 'rxjs'
import {HemnetService, IHemnetData} from '../../services/hemnet.service'
import {DataService} from '../../services/data.service'
import {filter} from 'rxjs/operators'

@Component({
  selector: 'spb-calculation',
  templateUrl: './calculation.component.html',
  styleUrls: ['./calculation.component.scss']
})
export class CalculationComponent implements OnInit {

  public monthlyCost: number
  public loanAmount: number
  public interest: number
  public runCost: number
  public amortization: number
  public fee: number
  public taxDeduction: number

  public amortizationPercent: number

  private hemnetData$: Subscription


  private rates = [
    {rate: 0.5, value: 0.01},
    {rate: 0.7, value: 0.02}
  ]

  constructor(
    private hemnetService: HemnetService,
  ) {
  }

  ngOnInit(): void {
    this.hemnetData$ = this.hemnetService.hemnetData.pipe(
      filter((hemnetData: IHemnetData) => hemnetData && !!hemnetData.expectedPrice)
    ).subscribe(
      {
        next: (hemnetData: IHemnetData) => {
          const keysToClean = ['expectedPrice', 'fee', 'downPayment', 'runCost']
          const copy = Object.assign({}, hemnetData)

          Object.keys(copy).forEach(key => {
            if (keysToClean.indexOf(key) !== -1) {
              copy[key] = DataService.StringToInt(copy[key])
            }
          })

          copy.runCost = copy.runCost || 0
          copy.fee = copy.fee || 0
          const loanAmount = copy.expectedPrice - copy.downPayment
          const interestRate = Number.parseFloat(copy.interestRate) / 100
          const yearlyInterest = loanAmount * interestRate
          const monthlyInterest = Math.ceil(yearlyInterest / 12)
          let monthlyTaxDeduction = Math.ceil(monthlyInterest * 0.30)
          if (yearlyInterest > 100000) {
            monthlyTaxDeduction = Math.ceil((30 * 1000 + ((yearlyInterest - (100 * 1000)) * 0.20)) / 12)
          }
          this.loanAmount = loanAmount
          this.fee = copy.fee

          this.amortization = Math.ceil((copy.expectedPrice - copy.downPayment) *
            this.getAmortizationPercent(copy.expectedPrice, copy.downPayment) / 12)
          this.runCost = Math.ceil(copy.runCost / 12)
          this.interest = monthlyInterest
          this.taxDeduction = monthlyTaxDeduction
          this.monthlyCost = this.fee + this.amortization + this.runCost + this.interest - this.taxDeduction
        }
      })
  }

  private getAmortizationPercent(price: number, downPayment: number): number {
    const degree = (price - downPayment) / price
    this.amortizationPercent = this.rates.reduce((current: number, rate: any) => degree > rate.rate ? rate.value : current, 0)
    return this.amortizationPercent
  }
}
