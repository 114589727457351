import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {HeaderComponent} from './header/header.component'
import {ContactComponent} from './footer/contact.component'
import {BreadcrumbsComponent} from './breadcrumbs/breadcrumbs.component'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'


@NgModule({
  declarations: [
    HeaderComponent,
    ContactComponent,
    BreadcrumbsComponent,
  ],
  imports: [
    CommonModule,
    ThemeModule
  ],
  exports: [
    HeaderComponent,
    ContactComponent,
    BreadcrumbsComponent,
  ]
})
export class HemnetCommonModule {
}
