import {Injectable} from '@angular/core'
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {SECRET} from '../../environments/secret'

/**
 * Inject to set auth header on all requests.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  /**
   * Intercept all requests and add x-api-key.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let headers = req.headers

    /**
     * Assign an x-api-key
     */
    if (req.url.indexOf('/interests') !== -1) {
      headers = headers.set('X-Api-Key', SECRET)
    }

    /**
     * Set no more headers
     */
    return next.handle(req.clone({
      headers
    }))
  }
}
