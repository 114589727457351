import {Injectable} from '@angular/core'
import {map} from 'rxjs/operators'
import {Observable} from 'rxjs'
import {HttpClient, HttpHeaders} from '@angular/common/http'
import {environment} from '../../environments/environment'


/**
 * Describes the latest valid interest
 */
export interface LatestValidInterest {
  month3: string
  year1: string
  year2: string
  year3: string
  year4: string
  year5: string
  interestStartDate: string
}


@Injectable({
  providedIn: 'root'
})
export class DisclaimerService {

  constructor(private httpClient: HttpClient) {
  }

  /**
   * Get the latest valid interest from database
   */
  public getLatestInterest(): Observable<LatestValidInterest> {
    const url = `${environment.rtUrl}/interests/latest`
    return this.httpClient.get<LatestValidInterest>(url)
  }

  /**
   * Get disclaimer text from backend.
   */
  public getDisclaimer(p: number, a: number, iR: number, sF: number, iStart: number): Observable<string> {
    let headers = new HttpHeaders()
    headers = headers.set('Content-Type', 'text/plain')

    const url = `${environment.rtUrl}/interests/` +
      `disclaimer?periods=${p}&amount=${a}&interestRate=${iR}&startFee=${sF}&interestStartDate=${iStart}`
    return this.httpClient.get(url, {
      headers,
      responseType: 'text'
    })
      .pipe(
        map((disclaimer: string) => disclaimer)
      )
  }
}
