import {Component, OnInit} from '@angular/core'
import {HemnetService, IHemnetData} from '../services/hemnet.service'
import {ActivatedRoute, ParamMap} from '@angular/router'
import {environment} from '../../environments/environment'
import {DisclaimerService} from '../services/disclaimer.service'
import {filter, first, switchMap} from 'rxjs/operators'

@Component({
  selector: 'spb-hemnet',
  templateUrl: './hemnet.component.html',
  styleUrls: ['./hemnet.component.scss']
})
export class HemnetComponent implements OnInit {
  /**
   * The disclaimer text from rt-backend
   */
  public disclaimerText = ''

  public utmTags: string = environment.utmTags

  public home: IHemnetData = {}

  constructor(
    private disclaimerService: DisclaimerService,
    private hemnetService: HemnetService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(
      {
        next: (params: ParamMap) => {
          const id = params.get('id')
          if (id) {
            this.hemnetService.getHemnetData(id)
          }
        }
      })

    /**
     * Variables needed in getDisclaimer()
     */
    const periods = 480
    const amount = 1000000
    const startFee = 0
    let interestStartDate = null
    let interestRate = null

    /**
     * Get the home and the disclaimer text on the bottom
     */
    this.hemnetService.hemnetData.pipe(
      filter((hemnetData: IHemnetData) => hemnetData && !!hemnetData.interestStartDate), //only go ahead if interestStartDate exists
      first(),
      switchMap((i: IHemnetData) => {
        interestRate = Number(i.interestRate)
        interestStartDate = Number(i.interestStartDate)
        return this.disclaimerService.getDisclaimer(periods, amount, interestRate / 100, startFee, interestStartDate)
      })
    ).subscribe(
      {
        next: (disclaimerText: string) => {
          this.disclaimerText = disclaimerText
        }
      }
    )
  }
}
